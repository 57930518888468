const clamp = (val, min, max) => {
	return Math.max(min, Math.min(val, max))
}
//cover delta scale - scale will go from 1-D_SCALE to 1
const D_SCALE = 0.037
//pct of viewport in center of screen where covers will be 'locked' to scale 1
const CENTER_LOCK = 0.2

class Home {
	constructor() {
		//bind scroll handler
		this.handleScroll = this.handleScroll.bind(this)
	}

	init() {
		this.header = document.querySelector('.c-nav')
		this.covers = [].slice.call(document.querySelectorAll('.b-cover'))
		//handle scroll on animation frame for performance reason
		requestAnimationFrame(this.handleScroll)
	}

	handleScroll() {
		requestAnimationFrame(this.handleScroll)
		// dont do anything if scroll position didn't change
		if (this.scrollTop === document.scrollingElement.scrollTop) {
			return
		}
		//new scroll pos
		this.scrollTop = document.scrollingElement.scrollTop
		let headerHeight = 0
		//do we have header
		if (this.header) {
			//dont do this in production bundle !!!
			if (!process.env.BASE_PATH) {
				// fixed header
				if (this.scrollTop > 140) {
					this.header.classList.add('is-fixed')
				} else {
					this.header.classList.remove('is-fixed')
				}
			}
			//header height changes
			headerHeight = Math.max(0, this.header.getBoundingClientRect().bottom)
		}

		//center of viewport without header
		const viewportCenter = window.innerHeight * 0.5 + headerHeight * 0.5
		this.covers.forEach(cover => {
			const coverBox = cover.getBoundingClientRect()
			const coverCenter = coverBox.top + coverBox.height * 0.5
			let scale = 1
			if (Math.abs(viewportCenter - coverCenter) > window.innerHeight * CENTER_LOCK) {
				//if center of cover is outside center lock area
				//check how far away it is
				const fct =
					Math.abs(viewportCenter - coverCenter) / (window.innerHeight * (1 - CENTER_LOCK))
				//clamp fct and calc scale
				scale = 1 - D_SCALE * clamp(fct, -1, 1)
				//hiding overlay
				cover.classList.remove('active')
			} else {
				//show overlay if it is inside center lock arrea
				cover.classList.add('active')
			}
			//apply transform
			cover.style.transform = `scale(${scale}, ${scale})`
			cover.style.WebkitTransform = `scale(${scale}, ${scale})`
		})
	}
}

const home = new Home()

module.exports = home
